import { EStatus } from 'types/common';
import { IBranchDeliveryInfo } from './branches';
import { IVendorAssociableBranch } from './vendors';

// ! enums & types ---------
export enum EMenuType {
	SIMPLE = 'simple',
	COMPLEX = 'complex',
}

export enum EMenuPurpose {
	DELIVERY = 'delivery',
	GATHERING = 'gathering',
	CATERING = 'catering',
}

// ! interfaces ---------
export interface IMenuEntityAssociationPayload {
	ids: number[];
}

interface ISnoozeMenuEntity {
	is_snoozed: boolean;
	start: string;
	end: string;
}

// * Entity can be Category, SubCategory, Product
export interface IMenuEntity {
	id: number;
	name: string;
	name_ar: string;
	image: string;
	status: EStatus;
	products?: IMenuEntity[];
	sub_categories?: IMenuEntity[];

	vendor_product_is_active?: boolean;
	menu_product_is_active?: boolean;

	vendor_category_is_active?: boolean;
	menu_category_is_active?: boolean;

	sort_order: number;
	snooze: ISnoozeMenuEntity;

	// ** Product Specific
	maximum_order_quantity?: number;
}

export interface IMenu {
	id: number;
	name: string;
	name_ar: string;
	type: EMenuType;
	purpose: EMenuPurpose;
	categories: IMenuEntity[];

	stores: Pick<IVendorAssociableBranch, 'id' | 'name'>[];

	delivery?: {
		order_delivery_time: IBranchDeliveryInfo['order_delivery_time'];
	};
}

export interface IMenuListItemResponse {
	id: IMenu['id'];
	name: IMenu['name'];
	name_ar: IMenu['name_ar'];
	type: IMenu['type'];
	purpose: IMenu['purpose'];
	stores: IMenu['stores'];
}

export interface IMenuPatchRequest {
	name?: IMenu['name'];
	name_ar?: IMenu['name_ar'];
	purpose?: IMenu['purpose'];
}

export interface IMenuCreateRequest {
	name: IMenu['name'];
	name_ar: IMenu['name_ar'];
	type: IMenu['type'];
	purpose: IMenu['purpose'];
}

export interface IMenuAddProduct {
	products: {
		id: number;
		sort_order: number;
	}[];
	sub_category_id?: number;
}

export interface IMenuAddCategory {
	categories: {
		id: number;
		sort_order: number;
	}[];
	parent_category_id?: number;
}

export interface IMenuTreeCoordinate {
	category_id: number;
	sub_category_id?: number;
	product_id?: number[] | number;
}
export interface IMenuDeleteItemsRequest {
	items: IMenuTreeCoordinate[];
}
export interface IMenuUpdateItemsStatusRequest {
	items: IMenuTreeCoordinate[];
	status: EStatus;
}

export interface IMenuItemsSortRequest {
	sorting: 'products' | 'categories' | 'sub_categories';
	target?: {
		category_id: number;
		sub_category_id?: number;
	};
	ids: number[]; // list item ids in order
}
