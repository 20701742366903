import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useGlobalConfigs, useHandleErrors } from 'hooks';
import { Nullable } from 'types/common';
import { IFetchDataConfig, INewHttpRequestClient, TError } from './types';

export const useHttpClient = <DataType,>(): INewHttpRequestClient<DataType> => {
	const { http } = useGlobalConfigs();
	const { handleError } = useHandleErrors();

	const [response, setResponse] = useState<Nullable<DataType>>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<TError>(null);

	const request = async (config: IFetchDataConfig<DataType>, signal?: AbortSignal): Promise<void> => {
		const { requestConfig, successCallback, errorCallback, finallyCallback, displayErrorMsg = true } = config;

		setIsLoading(true);
		setError(null);

		return http({ ...requestConfig, signal })
			.then((result: AxiosResponse<DataType>) => {
				setError(null);
				setResponse(result.data);
				successCallback?.(result.data, result);
			})
			.catch((error) => {
				setError(error);
				errorCallback?.(error);
				handleError(error, !error.__CANCEL__ && displayErrorMsg);

				if (config.withPromiseReject) {
					return Promise.reject(error);
				}
			})
			.finally(() => {
				setIsLoading(false);
				finallyCallback?.();
			});
	};

	return {
		response,
		isLoading,
		error,
		request,
	};
};
