import { THttpRequestConfig, THttpRequestParams } from 'hooks/useHttpClient';
import { EBranchScheduledOrderSupport, IBranch, IVendor } from 'types/api';
import { EOnOffStatus } from 'types/common';

export const VENDOR_BRANCH_API = {
	fetchList: (vendorId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores`,
		method: 'GET',
	}),

	genericList: (
		vendorId: number,
		name?: IBranch['info']['name'],
		params?: THttpRequestParams
	): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/generic`,
		method: 'GET',
		params: {
			...params,
			name,
		},
	}),

	get: (vendorId: number, branchId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/${branchId}`,
		method: 'GET',
	}),

	statusUpdate: (vendorId: number, branchId: number, status: EOnOffStatus): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/${branchId}/current-status`,
		method: 'PATCH',
		data: { status },
	}),

	setBulkScheduleOrderMode: (
		vendorId: number,
		data?: { scheduled_order_support: EBranchScheduledOrderSupport; store_ids?: number[] }
	): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/stores/scheduled-order-support`,
		method: 'PATCH',
		data,
	}),

	bulkStatusUpdate: (vendorId: number, status: EOnOffStatus): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/current-status`,
		method: 'PATCH',
		data: { status },
	}),

	getDeliveryAreas: (vendorId: IVendor['id'], storeId: IBranch['id']): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/${storeId}/delivery-areas`,
		method: 'GET',
	}),
};
