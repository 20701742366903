import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { useAuth, useAuthenticatedInformation, useBranchDrawer } from 'hooks';
import { NotificationDrawerOpener } from 'components/NotificationsProviderComponents/NotificationDrawerOpener';
import { BranchSelector } from './BranchSelector';
import styles from './Header.module.css';
import TimezoneSelector from './TimezoneSelector';
import { Avatar, Button, Dropdown, MenuProps, Space, Typography as T } from 'antd';
import { SettingOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';

export const Header: FC = () => {
	const auth = useAuth();
	const { user } = useAuthenticatedInformation();
	const { openDrawer } = useBranchDrawer();
	const location = useLocation();
	const navigate = useNavigate();
	const { t: tCommon } = useTranslation('common');

	// ! handlers
	const onUserInfoClickItem = () => {
		navigate(ABSOLUTE_ROUTES.USER_SETTINGS, {
			state: { from: location },
		});
	};

	const userMenu = useMemo<MenuProps>(
		() => ({
			selectable: false,
			items: [
				{
					key: 'user-info',
					onClick: onUserInfoClickItem,
					label: (
						<>
							<T.Paragraph className={styles.name}>{user.first_name}</T.Paragraph>
							<T.Paragraph className={styles.email}>{user.email}</T.Paragraph>
						</>
					),
				},
				{ type: 'divider' },

				{
					key: 'change-branch',
					icon: <ShopOutlined />,
					onClick: () => openDrawer(),
					label: tCommon('header.user.change_branch'),
				},
				{
					key: 'user-settings',
					icon: <SettingOutlined />,
					label: tCommon('header.user.settings'),
					onClick: onUserInfoClickItem,
				},

				{ type: 'divider' },
				{
					key: 'logout-btn',
					className: styles.logout_btn,
					label: (
						<Button
							block
							onClick={() => auth.signout()}
						>
							{tCommon('header.user.sign_out')}
						</Button>
					),
				},
			],
		}),
		[auth] // eslint-disable-line react-hooks/exhaustive-deps
	);

	// ! render
	return (
		<div className={styles.app_header}>
			{/* RIGHT */}
			<BranchSelector />

			{/* LEFT */}
			<Space size='large'>
				<TimezoneSelector />
				<NotificationDrawerOpener />
				<Dropdown
					menu={userMenu}
					placement='bottomLeft'
					arrow={{ pointAtCenter: true }}
				>
					<Avatar
						size={40}
						icon={<UserOutlined />}
						className={styles.avatar}
					/>
				</Dropdown>
			</Space>
		</div>
	);
};
