import { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { VENDOR_API } from 'configs/api';
import { useAuthenticatedInformation, useHttpClient, useIntegrations } from 'hooks';
import { IVendor } from 'types/api';
import { Nullable, TEmptyFunction } from 'types/common';
import { IFEIntegrationSetting } from 'contexts/integrations/types';

interface IVendorContextData {
	vendorId: number;
	data: Nullable<IVendor>;
	error: any;
	isLoading: boolean;

	//
	fetchData: TEmptyFunction;
	integrationSettings: Nullable<IFEIntegrationSetting>;
}

export const VendorContext = createContext<IVendorContextData>({} as IVendorContextData);

const useVendor = () => {
	return useContext(VendorContext);
};

interface IVendorProviderProps extends PropsWithChildren {
	vendorId?: number;
}

const VendorProvider: FC<IVendorProviderProps> = ({ vendorId: vendorIdArg, children = <Outlet /> }) => {
	const { user } = useAuthenticatedInformation();
	const { getIntegrationTypeSettings } = useIntegrations();

	const { vendorId: VendorIdParameter } = useParams();

	// ! http client
	const fetchVendorDataHttpClient = useHttpClient<IVendor>();

	// ! states
	const [data, setData] = useState<IVendor>();

	// ! memos
	const vendorId = useMemo(
		() => vendorIdArg ?? +(VendorIdParameter ?? user.vendorId),
		[VendorIdParameter, user.vendorId, vendorIdArg]
	);

	// ! handlers
	const fetchData = () => {
		if (!vendorId || fetchVendorDataHttpClient.isLoading) return;

		fetchVendorDataHttpClient.request({
			requestConfig: VENDOR_API.getDetails(vendorId),
			successCallback: (response) => {
				setData(response);
			},
		});
	};

	const getVendorIntegrationSettings = () => {
		if (!data) return null;

		return getIntegrationTypeSettings(data.info.integration_type);
	};

	// ! effects
	useEffect(() => {
		fetchData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendorId]);

	// ! render
	if (!vendorId) return null;

	const vendorData: IVendorContextData = {
		vendorId,
		data: fetchVendorDataHttpClient.response,
		error: fetchVendorDataHttpClient.error,
		isLoading: fetchVendorDataHttpClient.isLoading,

		// handlers
		fetchData,
		integrationSettings: getVendorIntegrationSettings(),
	};

	return <VendorContext.Provider value={vendorData}>{children}</VendorContext.Provider>;
};

export { useVendor, VendorProvider };
