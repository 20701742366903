import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IDrawerHeaderProps } from './types';
import PromotionStatusTag from '../../PromotionUsageStatusTag/PromotionUsageStatusTag';
import { Flex, Typography as T } from 'antd';

const DrawerHeader: FC<IDrawerHeaderProps> = ({ campaignId, status, startDate }) => {
	const { t: tCampaigns } = useTranslation('campaigns');

	// ! render
	return (
		<Flex
			gap='middle'
			align='center'
		>
			<T.Title
				level={3}
				style={{ marginBottom: 0 }}
			>
				{tCampaigns('view.title')}
			</T.Title>

			<T.Text type='secondary'>{`#${campaignId}`}</T.Text>

			<PromotionStatusTag
				status={status}
				startDate={startDate}
			/>
		</Flex>
	);
};

export default DrawerHeader;
