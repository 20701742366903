import { THttpRequestConfig, THttpRequestParams } from 'hooks/useHttpClient';
import {
	IBranchMenusSortPayload,
	IBranchesAssociationPayload,
	IMenuAddCategory,
	IMenuAddProduct,
	IMenuCreateRequest,
	IMenuDeleteItemsRequest,
	IMenuItemsSortRequest,
	IMenuPatchRequest,
	IMenuUpdateItemsStatusRequest,
	IToggleSnoozeProductPayload,
} from 'types/api';

// ! Menus
export const VENDOR_MENU_API = {
	fetchList: (vendorId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus`,
		method: 'GET',
	}),
	createMenu: (vendorId: number, data: IMenuCreateRequest): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus`,
		method: 'POST',
		data,
	}),
	getMenu: (vendorId: number, menuId: number, search?: string, type?: string): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus/${menuId}`,
		method: 'GET',
		params: {
			[type || 'search']: search,
		},
	}),
	patchMenu: (vendorId: number, menuId: number, data: IMenuPatchRequest): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus/${menuId}`,
		method: 'PATCH',
		data,
	}),
	deleteMenu: (vendorId: number, menuId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus/${menuId}`,
		method: 'DELETE',
	}),

	//stores
	getStores: (vendorId: number, menuId: number, params?: THttpRequestParams): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus/${menuId}/associable-stores`,
		method: 'GET',
		params: {
			is_associated: true,
			...params,
		},
	}),
	postStores: (vendorId: number, menuId: number, data: IBranchesAssociationPayload): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus/${menuId}/stores`,
		method: 'POST',
		data,
	}),
	getAssociableStores: (vendorId: number, menuId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus/${menuId}/associable-stores`,
		method: 'GET',
	}),
	getUnassociatedBranches: (vendorId: number, params?: THttpRequestParams): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus/unassociated-branches`,
		method: 'GET',
		params,
	}),

	// menu entities
	addProduct: (vendorId: number, menuId: number, categoryId: number, data: IMenuAddProduct): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus/${menuId}/categories/${categoryId}/products`,
		method: 'POST',
		data,
	}),
	addCategory: (vendorId: number, menuId: number, data: IMenuAddCategory): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus/${menuId}/categories`,
		method: 'POST',
		data,
	}),
	sortMenuEntity: (vendorId: number, menuId: number, payload: IMenuItemsSortRequest): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus/${menuId}/sort-order`,
		method: 'PATCH',
		data: payload,
	}),
	deleteTreeNode: (vendorId: number, menuId: number, data: IMenuDeleteItemsRequest): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus/${menuId}/delete-items`,
		method: 'POST',
		data,
	}),
	updateTreeNodeStatus: (
		vendorId: number,
		menuId: number,
		data: IMenuUpdateItemsStatusRequest
	): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/menus/${menuId}/items-status`,
		method: 'PATCH',
		data,
	}),
};

export const VENDOR_MENU_SNAPSHOT_API = {
	getBranchMenus: (vendorId: number, branchId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/${branchId}/menus`,
		method: 'GET',
	}),
	getMenu: (
		vendorId: number,
		branchId: number,
		menuId: number,
		search?: string,
		type?: string
	): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/${branchId}/menus/${menuId}`,
		method: 'GET',
		params: { [type || 'search']: search },
	}),
	patchMenusSort: (vendorId: number, branchId: number, data: IBranchMenusSortPayload): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/${branchId}/menus/sort`,
		method: 'PATCH',
		data,
	}),
	updateTreeNodeStatus: (
		vendorId: number,
		branchId: number,
		menuId: number,
		data: IMenuUpdateItemsStatusRequest
	): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/items-status`,
		method: 'PATCH',
		data,
	}),
	toggleSnooze: (
		vendorId: number,
		menuId: number,
		branchId: number,
		productId: number,
		data: IToggleSnoozeProductPayload
	): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/products/${productId}/snooze`,
		method: 'PATCH',
		data,
	}),
	manualSync: (vendorId: number, branchId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/stores/${branchId}/menus/sync`,
		method: 'POST',
	}),
};
