import { Property } from 'csstype';
import { EPromotionStatus } from 'types/api/promotions';
import { TFinalStatus } from './types';

export const PROMOTION_STATUS_TAG_COLORS: Record<TFinalStatus, Property.Color> = {
	[EPromotionStatus.PENDING]: 'orange',
	[EPromotionStatus.PAUSED]: 'yellow',
	[EPromotionStatus.IN_PROGRESS]: 'lime',
	[EPromotionStatus.COMPLETED]: 'green',
	scheduled: 'purple',
};
