import { THttpRequestConfig } from 'hooks/useHttpClient';
import { IVendorUser, IVendorUserPayload } from 'types/api/vendorUsers';
import { IBranchesAssociationPayload } from 'types/api/vendors';

export const VENDOR_API = {
	getDetails: (vendorId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}`,
		method: 'GET',
	}),
};

export const VENDOR_STAFF_API = {
	list: (vendorId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/users`,
		method: 'GET',
	}),
	create: (vendorId: number, data: IVendorUserPayload): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/users`,
		method: 'POST',
		data,
	}),
	get: (vendorId: number, userId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/users/${userId}`,
		method: 'GET',
	}),
	update: (
		vendorId: number,
		userId: number,
		data: Partial<IVendorUserPayload> | Pick<IVendorUser, 'status'>
	): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/users/${userId}`,
		method: 'PATCH',
		data,
	}),
	delete: (vendorId: number, userId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/users/${userId}`,
		method: 'DELETE',
	}),

	resetPassword: (vendorId: number, userId: number, password: string): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/users/${userId}/change-password`,
		method: 'POST',
		data: { password },
	}),

	associableBranchList: (vendorId: number, userId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/users/${userId}/associable-stores`,
		method: 'GET',
	}),
	associateBranch: (vendorId: number, userId: number, data: IBranchesAssociationPayload): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/users/${userId}/stores`,
		method: 'POST',
		data,
	}),
};
