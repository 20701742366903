import { ESupportedLanguages } from '../common';
import { IVendorUserAssociatedBranches } from './branchUsers';

export enum EVendorUserType {
	VENDOR_MANAGER = 'vendor-manager',
	BRANCH_MANAGER = 'branch-manager',
	FINANCE_MANAGER = 'finance-manager',
}

export enum EVendorUserStatus {
	PENDING = 'pending',
	ACTIVE = 'active',
	BLOCKED = 'blocked',
}

export interface IVendorUser {
	id: number;
	first_name: string;
	last_name: string;
	type: EVendorUserType;
	email: string;
	country_id: number;
	country_code: string;
	phone_number: string;
	language: ESupportedLanguages;
	created_at?: string;
	updated_at?: string;
	status: EVendorUserStatus;
	status_description: string;
	stores: IVendorUserAssociatedBranches[];
}

export interface IVendorUserItemListResponse {
	id: number;
	first_name: string;
	last_name: string;
	type: EVendorUserType;
	email: string;
	status: EVendorUserStatus;
	phone_number: string;
	country_code: string;
	language: ESupportedLanguages;
	stores: Array<{
		id: IVendorUserAssociatedBranches['id'];
		name: IVendorUserAssociatedBranches['name'];
	}>;
}

// ! CRUD
export interface IVendorUserPayload {
	id?: IVendorUser['id'];
	first_name: IVendorUser['first_name'];
	last_name: IVendorUser['last_name'];
	type: IVendorUser['type'];
	email: IVendorUser['email'];
	phone_number: IVendorUser['phone_number'];
	country_code: IVendorUser['country_code'];
	language: IVendorUser['language'];

	password?: string;
}
