import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PROMOTIONS_API } from 'configs/api/promotions';
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { LocalTimeDate, useHttpClient } from 'hooks';
import { useCountry } from 'hooks/useCountry';
import { DATE_TIME_INTL_FORMAT } from 'utils/date';
import {
	ECampaignFormFields,
	EPromotionDiscountType,
	EPromotionUsageType,
	IPromotionListResponse,
} from 'types/api/promotions';
import { Nullable } from 'types/common';
import { IViewCampaignDrawerProps } from './types';
import { CopyToClipBoard } from 'components/CopyToClipBoard';
import CustomDrawer from 'components/CustomDrawer';
import { TextSplitter } from 'components/TextSplitter';
import TranslatedLabel from 'components/TranslatedLabel';
import FieldCard from '../FieldCard';
import { formatDateCampaignDate } from '../TimeRangeSlider/TimeRangeSlider.configs';
import DrawerHeader from './DrawerHeader';
import dayjs from 'dayjs';
import { Col, Flex, Row, Space, Tag, Typography } from 'antd';
import { SwapRightOutlined } from '@ant-design/icons';

const ViewCampaignDrawer: FC<IViewCampaignDrawerProps> = ({ open, vendorId, campaignId, handleOnClose }) => {
	const { t: tCampaigns } = useTranslation('campaigns');

	const { currentCountry } = useCountry();

	// ! http clients
	const fetchCampaignHttpClient = useHttpClient<IPromotionListResponse>();

	// ! states
	const [campaignData, setCampaignData] = useState<Nullable<IPromotionListResponse>>(null);

	// ! effects
	useEffect(() => {
		if (!campaignId) return;

		fetchCampaignHttpClient.request({
			requestConfig: PROMOTIONS_API.get(vendorId, campaignId),
			successCallback: (data) => setCampaignData(data),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campaignId]);

	// ! render
	if (!campaignData) {
		return null;
	}

	const isFreeDelivery = campaignData?.configurations.discount_type === EPromotionDiscountType.FREE_DELIVERY;
	const isPercentagePromotion = campaignData?.configurations.discount_type === EPromotionDiscountType.PERCENTAGE;
	const isAmount = campaignData?.configurations.discount_type === EPromotionDiscountType.AMOUNT;

	const isManualType = campaignData?.configurations.usage_type === EPromotionUsageType.MANUAL;

	const vendorList = campaignData?.vendor_list ?? [];

	const vendorListField = () => {
		return vendorList.map((vendor) => {
			const hasMultiBranchList = !!(
				vendor[ECampaignFormFields.MULTI_VENDOR_BRANCH_LIST] &&
				vendor[ECampaignFormFields.MULTI_VENDOR_BRANCH_LIST].length
			);

			return (
				<Space
					direction='vertical'
					key={vendor.id}
				>
					{`#${vendor.id} - ${vendor.name}`}
					<Flex
						gap={8}
						vertical={hasMultiBranchList}
					>
						<Typography.Text>{tCampaigns('view.affected_stores')}</Typography.Text>
						{hasMultiBranchList ? (
							<Space wrap>
								{vendor[ECampaignFormFields.MULTI_VENDOR_BRANCH_LIST]?.map((store, index: number) => (
									<Tag key={index}>{`#${store.id} - ${store.name}`}</Tag>
								))}
							</Space>
						) : (
							DEFAULT_EMPTY_VALUE_PLACEHOLDER
						)}
					</Flex>
				</Space>
			);
		});
	};

	return (
		<CustomDrawer
			closable
			open={open}
			size='large'
			loading={fetchCampaignHttpClient.isLoading}
			title={
				<DrawerHeader
					campaignId={campaignData.id}
					status={campaignData.info.status}
					startDate={campaignData.duration.start_date}
				/>
			}
			onClose={handleOnClose}
		>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<FieldCard
						fieldName={tCampaigns('table.headers.title')}
						fieldValue={
							<Flex
								className='w-100'
								vertical
							>
								<Space split={<TextSplitter />}>
									{campaignData.info.title}
									{campaignData.info.title_ar}
								</Space>

								<Typography.Text type='secondary'>{campaignData.info.description}</Typography.Text>

								<Typography.Text type='secondary'>{campaignData.info.description_ar}</Typography.Text>
							</Flex>
						}
					/>
				</Col>
				<Col span={24}>
					<FieldCard
						fieldName={tCampaigns('view.vendor_title')}
						fieldValue={vendorListField()}
					/>
				</Col>

				<Col span={16}>
					<FieldCard
						fieldName={tCampaigns('view.time_range.label')}
						fieldValue={
							<Space wrap={false}>
								<LocalTimeDate
									date={campaignData.duration.start_date}
									format={DATE_TIME_INTL_FORMAT}
								/>
								<SwapRightOutlined />
								<LocalTimeDate
									date={campaignData.duration.end_date}
									format={DATE_TIME_INTL_FORMAT}
								/>
							</Space>
						}
					/>
				</Col>
				<Col span={8}>
					<FieldCard
						fieldName={tCampaigns('view.time_range.sub.title')}
						fieldValue={formatDateCampaignDate(dayjs.duration(campaignData.duration.time_range, 'seconds'))}
					/>
				</Col>
				<Col span={12}>
					<FieldCard
						fieldName={tCampaigns('view.target')}
						fieldValue={
							<TranslatedLabel
								nameSpace='campaigns'
								i18nKey={campaignData.configurations.target}
								extra={{ keyPrefix: 'enums.target' }}
							/>
						}
					/>
				</Col>

				<Col span={12}>
					<FieldCard
						fieldName={tCampaigns('view.discount_amount')}
						fieldValue={
							<>
								{isFreeDelivery ? (
									tCampaigns(`enums.discount_type.free_delivery`)
								) : (
									<Space>
										{tCampaigns(`${campaignData.configurations.discount_amount}`)}
										{isPercentagePromotion && '%'}
										{isAmount && currentCountry?.display_currency_code}
									</Space>
								)}
							</>
						}
					/>
				</Col>
				<Col span={12}>
					<FieldCard
						fieldName={tCampaigns('view.usage_type.label')}
						fieldValue={
							<TranslatedLabel
								nameSpace='campaigns'
								i18nKey={`view.usage_type.${campaignData.configurations.usage_type}.label`}
							/>
						}
					/>
				</Col>

				{isManualType && (
					<Col span={12}>
						<FieldCard
							fieldName={tCampaigns('view.code')}
							fieldValue={
								<CopyToClipBoard reverted={true}>{campaignData.configurations.code}</CopyToClipBoard>
							}
						/>
					</Col>
				)}

				<Col span={12}>
					<FieldCard
						fieldName={tCampaigns('view.min_order_amount.label')}
						fieldValue={campaignData.configurations.min_order_amount}
					/>
				</Col>

				<Col span={12}>
					<FieldCard
						fieldName={tCampaigns('view.max_discount_amount.label')}
						fieldValue={campaignData.configurations.max_discount_amount}
					/>
				</Col>

				<Col span={12}>
					<FieldCard
						fieldName={tCampaigns('view.global_usage_limit.label')}
						fieldValue={campaignData.usage.global_usage_limit}
					/>
				</Col>

				{campaignData.usage.user_usage_limit !== undefined && (
					<Col span={12}>
						<FieldCard
							fieldName={tCampaigns('view.user_usage_limit.label')}
							fieldValue={campaignData.usage.user_usage_limit}
						/>
					</Col>
				)}
			</Row>
		</CustomDrawer>
	);
};

export default ViewCampaignDrawer;
