import { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PROMOTIONS_API } from 'configs/api/promotions';
import { useGetFormValues, useHttpClient, useLocaleTimeUtils } from 'hooks';
import { useCountry } from 'hooks/useCountry';
import { preserveUndefinedDateWhenTransformToBEAcceptanceFormat } from './helper';
import {
	ECampaignFormFields,
	EPromotionDiscountType,
	EPromotionTarget,
	EPromotionType,
	EPromotionUsageType,
	ICampaignForm,
	IPromotionCreatePayloadTemp,
	IPromotionListResponse,
} from 'types/api';
import { TEmptyFunction } from 'types/common';
import { PROMOTION_DEFAULT_VALUES, PROMOTION_TARGET_SPECIFIC_DEFAULTS } from '../config';
import dayjs from 'dayjs';
import { App } from 'antd';
import { FormInstance, useForm, useWatch } from 'antd/es/form/Form';

export interface ICampaignCreateController {
	form: FormInstance<ICampaignForm>;

	promotion_type_translation: string;

	isSaving: boolean;
	isPromotionCode: boolean;
	isFreeDelivery: boolean;
	isFetching: boolean;

	// useWatch
	formData: Partial<ICampaignForm>;

	// Actions
	onCreateEditCancel: TEmptyFunction;
	handleCreateCampaign: (isLaunchOnCreate: boolean) => void;
	handleTargetChange: (value: EPromotionTarget) => void;
	handleIncentiveTypeChange: (value: EPromotionDiscountType) => void;
}

const useCampaignCreateController = (promotionType = EPromotionType.CAMPAIGN): ICampaignCreateController => {
	const navigate = useNavigate();
	const { t: tCampaigns } = useTranslation('campaigns');

	const { getFormValues } = useGetFormValues();

	const { currentCountry } = useCountry();
	const { message } = App.useApp();
	const localTimeUtils = useLocaleTimeUtils();

	const { vendorId } = useParams();
	const [searchParams] = useSearchParams();

	const [form] = useForm<ICampaignForm>();

	// ! http clients
	const createHttpClient = useHttpClient<ICampaignForm>();
	const fetchPromotionHttpClient = useHttpClient<IPromotionListResponse>();

	const formData: Partial<ICampaignForm> = {
		[ECampaignFormFields.DISCOUNT_AMOUNT]: useWatch(ECampaignFormFields.DISCOUNT_AMOUNT, form),
		[ECampaignFormFields.DISCOUNT_TYPE]: useWatch(ECampaignFormFields.DISCOUNT_TYPE, form),
		[ECampaignFormFields.TARGET]: useWatch(ECampaignFormFields.TARGET, form),
		[ECampaignFormFields.USAGE_TYPE]: useWatch<EPromotionUsageType>(ECampaignFormFields.USAGE_TYPE, form),
		[ECampaignFormFields.FREE_DELIVERY]: useWatch(ECampaignFormFields.FREE_DELIVERY, form),
		[ECampaignFormFields.START_DATE]: useWatch(ECampaignFormFields.START_DATE, form),
		[ECampaignFormFields.END_DATE]: useWatch(ECampaignFormFields.END_DATE, form),
		[ECampaignFormFields.TIME_RANGE]: useWatch(ECampaignFormFields.TIME_RANGE, form),
	};

	const promotion_type_translation = tCampaigns(`promotion.type.${promotionType}`);

	const isPromotionCode = promotionType === EPromotionType.CODE;
	const isFreeDelivery = formData[ECampaignFormFields.DISCOUNT_TYPE] === EPromotionDiscountType.FREE_DELIVERY;

	// ! effects
	useEffect(() => {
		const start_date = formData[ECampaignFormFields.START_DATE];
		const time_range = formData[ECampaignFormFields.TIME_RANGE];

		let newEndDate = undefined;
		if (start_date && time_range) {
			newEndDate = start_date.add(time_range, 'seconds');
		}

		form.setFieldValue(ECampaignFormFields.END_DATE, newEndDate);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData.start_date, formData.time_range]);

	useEffect(() => {
		form.setFieldValue(
			ECampaignFormFields.USER_USAGE_LIMIT,
			(formData.target &&
				PROMOTION_TARGET_SPECIFIC_DEFAULTS[formData.target]?.[ECampaignFormFields.USER_USAGE_LIMIT]) ||
				undefined
		);
	}, [formData.target]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isFreeDelivery) {
			form.setFieldValue(ECampaignFormFields.FREE_DELIVERY, true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData.discount_type, isFreeDelivery]);

	useEffect(() => {
		if (form) form.setFieldValue(ECampaignFormFields.VENDOR_ID, vendorId);
	}, [form, vendorId]);

	useEffect(() => {
		const defaultData = {
			...PROMOTION_DEFAULT_VALUES,
			[ECampaignFormFields.MULTI_VENDOR]: [
				{
					[ECampaignFormFields.MULTI_VENDOR_VENDOR_ID]: +vendorId!,
					[ECampaignFormFields.MULTI_VENDOR_BRANCH_LIST]: [],
				},
			],
		};

		form.setFieldsValue(defaultData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentCountry?.id]);

	useEffect(() => {
		const promotionId = searchParams.get('promotionId');

		if (promotionId && vendorId) {
			fetchPromotionHttpClient.request({
				requestConfig: PROMOTIONS_API.get(+vendorId, +promotionId),
				successCallback: (response) => {
					const formData = {
						...response.info,
						...response.configurations,
						...response.usage,
						...response.duration,

						start_date: response.duration.start_date && dayjs(response.duration.start_date),
						end_date: response.duration.end_date && dayjs(response.duration.end_date),
						time_range: response.duration.time_range,
					};

					form.setFieldsValue(formData);
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		form,
		// translations
		promotion_type_translation,

		// useWatch
		formData,

		isSaving: createHttpClient.isLoading,

		isPromotionCode,
		isFreeDelivery,

		isFetching: fetchPromotionHttpClient.isLoading,

		// actions
		onCreateEditCancel: () => {
			navigate('..');
		},
		handleCreateCampaign: async (isLaunchOnCreate) => {
			const values = await getFormValues<ICampaignForm>(form);

			if (!values || !vendorId) return;

			let start_date = values[ECampaignFormFields.START_DATE];
			start_date = start_date?.set('seconds', 0);

			let end_date = values[ECampaignFormFields.END_DATE];
			end_date = end_date?.set('seconds', 0);

			let payload: IPromotionCreatePayloadTemp = {
				[ECampaignFormFields.LAUNCH_ON_CREATE]: isLaunchOnCreate,

				type: EPromotionType.CAMPAIGN,

				start_date: preserveUndefinedDateWhenTransformToBEAcceptanceFormat(
					start_date?.tz(localTimeUtils.selectedTimezone, true)
				),

				end_date: preserveUndefinedDateWhenTransformToBEAcceptanceFormat(end_date),

				[ECampaignFormFields.TIME_RANGE]: isPromotionCode ? undefined : values[ECampaignFormFields.TIME_RANGE],

				usage_limit_per_user: values.user_usage_limit,

				// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

				[ECampaignFormFields.MULTI_VENDOR]: values[ECampaignFormFields.MULTI_VENDOR],

				// others
				[ECampaignFormFields.TARGET]: values[ECampaignFormFields.TARGET],
				[ECampaignFormFields.DISCOUNT_TYPE]: values[ECampaignFormFields.DISCOUNT_TYPE],
				[ECampaignFormFields.DISCOUNT_AMOUNT]: values[ECampaignFormFields.DISCOUNT_AMOUNT],
				[ECampaignFormFields.TITLE]: values[ECampaignFormFields.TITLE],
				[ECampaignFormFields.TITLE_AR]: values[ECampaignFormFields.TITLE_AR],
				[ECampaignFormFields.DESCRIPTION]: values[ECampaignFormFields.DESCRIPTION],
				[ECampaignFormFields.DESCRIPTION_AR]: values[ECampaignFormFields.DESCRIPTION_AR],
				[ECampaignFormFields.USAGE_TYPE]: values[ECampaignFormFields.USAGE_TYPE],
				[ECampaignFormFields.GLOBAL_USAGE_LIMIT]: values[ECampaignFormFields.GLOBAL_USAGE_LIMIT],
				[ECampaignFormFields.FREE_DELIVERY]: values[ECampaignFormFields.FREE_DELIVERY],
				[ECampaignFormFields.STATUS]: values[ECampaignFormFields.STATUS],
				[ECampaignFormFields.CODE]: values[ECampaignFormFields.CODE] ?? '',
				[ECampaignFormFields.MINIMUM_ORDER_AMOUNT]: values[ECampaignFormFields.MINIMUM_ORDER_AMOUNT],
				[ECampaignFormFields.MAXIMUM_DISCOUNT_AMOUNT]: values[ECampaignFormFields.MAXIMUM_DISCOUNT_AMOUNT],
			};

			createHttpClient.request({
				requestConfig: PROMOTIONS_API.create(+vendorId, payload),
				successCallback: () => {
					navigate(`/campaigns/${vendorId}`);
					message.success(tCampaigns('actions.launch.success'));
				},
			});
		},
		handleTargetChange: (value: EPromotionTarget) => {
			form.setFieldsValue({
				[ECampaignFormFields.TARGET]: value,
				...PROMOTION_TARGET_SPECIFIC_DEFAULTS[value],
			} as ICampaignForm);
		},
		handleIncentiveTypeChange: (value: EPromotionDiscountType) => {
			form.setFieldValue(ECampaignFormFields.DISCOUNT_TYPE, value);

			if (form.getFieldValue(ECampaignFormFields.DISCOUNT_TYPE) === EPromotionDiscountType.FREE_DELIVERY) {
				form.setFieldValue(ECampaignFormFields.USAGE_TYPE, EPromotionUsageType.AUTOMATIC);
			}

			if (formData.target === EPromotionTarget.ACQUISITION) {
				form.setFieldValue(
					ECampaignFormFields.DISCOUNT_AMOUNT,
					PROMOTION_TARGET_SPECIFIC_DEFAULTS[EPromotionTarget.ACQUISITION]![
						ECampaignFormFields.DISCOUNT_AMOUNT
					]
				);
			}

			if (formData.target === EPromotionTarget.RETENTION) {
				form.setFieldValue(
					ECampaignFormFields.DISCOUNT_AMOUNT,
					PROMOTION_TARGET_SPECIFIC_DEFAULTS[EPromotionTarget.RETENTION]![ECampaignFormFields.DISCOUNT_AMOUNT]
				);
			}
		},
	};
};

const Context = createContext<ICampaignCreateController>({} as ICampaignCreateController);

const useCampaignCreateContext = () => {
	return useContext(Context);
};

const PromotionCreateProvider = ({ children = <Outlet /> }: PropsWithChildren) => {
	const contextData = useCampaignCreateController();

	// ! render
	return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export { PromotionCreateProvider, useCampaignCreateContext };
