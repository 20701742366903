import { lazy } from 'react';
import { TRouteObject } from 'configs/routes';
import TranslatedLabel from 'components/TranslatedLabel';

const ProductDetailsTab = lazy(() => import('./Tabs/ProductDetailsTab'));
const ProductVariantsTab = lazy(() => import('./Tabs/ProductVariantsTab'));
const ProductOptionGroupsTab = lazy(() => import('./Tabs/ProductOptionGroupsTab'));

export enum EProductTabsKeys {
	DETAILS = 'details',
	VARIANTS = 'variants',
	OPTION_GROUPS = 'option_groups',
}

export const PRODUCT_DETAILS_TAB_ROUTES_CONFIG: Record<EProductTabsKeys, TRouteObject> = {
	[EProductTabsKeys.DETAILS]: {
		path: EProductTabsKeys.DETAILS,
		element: <ProductDetailsTab />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='product'
					i18nKey='sections.tabs.details'
				/>
			),
		},
	},
	[EProductTabsKeys.VARIANTS]: {
		path: EProductTabsKeys.VARIANTS,
		element: <ProductVariantsTab />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='product'
					i18nKey='sections.tabs.variants'
				/>
			),
		},
	},
	[EProductTabsKeys.OPTION_GROUPS]: {
		path: EProductTabsKeys.OPTION_GROUPS,
		element: <ProductOptionGroupsTab />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='product'
					i18nKey='sections.tabs.options_groups'
				/>
			),
		},
	},
};

export const PRODUCT_DETAILS_TAB_ROUTES: TRouteObject[] = Object.values(PRODUCT_DETAILS_TAB_ROUTES_CONFIG);
