import { TIntegrationType } from './integration';
import { IMenuTreeCoordinate } from './menus';
import { IVertical } from './verticals';

// ! enums & types ---------
type TVendorProductsPerRow = 1 | 2;

export enum EVendorStatus {
	PENDING = 'pending',
	APPROVED = 'approved',
	BLOCKED = 'blocked',
}

export enum EBusinessModel {
	CAM = 'cam', // Customer Acquisition Model
	PCM = 'pcm', // Platform Commission Model
}

enum EVendorLinkEventStatsEvent {
	CLICK = 'CLICK',
	REDIRECT = 'REDIRECT',
	APP_INSTALL = 'APP_INSTALL',
	APP_FIRST_OPEN = 'APP_FIRST_OPEN',
	APP_RE_OPEN = 'APP_RE_OPEN',
}

export enum EVendorLinkEventStatsPlatform {
	ANDROID = 'ANDROID',
	IOS = 'IOS',
	DESKTOP = 'DESKTOP',
}

export enum EShiftOrigin {
	PLATFORM = 'platform',
	INTEGRATION = 'integration',
}

export enum EVendorType {
	FOOD = 'food',
	GROCERIES = 'groceries',
	PHARMACY = 'pharmacy',
	ELECTRONICS = 'electronics',
	FLOWERS = 'flowers',
}

export enum EBranchesAssociationPayloadAssociationType {
	ALL = 'all',
	CHOSEN = 'chosen',
	NONE = 'none',
}

export enum EMenuAssociation {
	ASSOCIATED = 'associated',
	UNASSOCIATED = 'unassociated',
}

// ! interfaces ---------
interface IVendorInfo {
	id: number;
	name: string;
	name_ar: string;
	display_name: string;
	display_name_ar: string;
	description: string;
	description_ar?: string;
	status: EVendorStatus;
	business_model: EBusinessModel;
	products_per_row: TVendorProductsPerRow;
	cam_reg_code?: string;
	store_commission?: number;
	integration_type: TIntegrationType;
	shift_source: EShiftOrigin;
	type?: EVendorType;
}

interface IVendorImages {
	banner: string;
	image: string;
}

interface IVendorContactInfo {
	phone_country_code: string;
	contact_number: string;
}

interface IVendorActions {
	can_search_product_variant_barcode: boolean;
	can_search_product_variant_sku: boolean;
}

export interface IVendor {
	id: number;
	info: IVendorInfo;
	images: IVendorImages;
	contact_info: IVendorContactInfo;
	verticals: IVertical[];
	actions: IVendorActions;
}

// * Associable Branches
export interface IVendorAssociableBranch {
	id: number;
	name: string;
	name_ar: string;
	is_associated: boolean;
}

export interface IBranchesAssociationPayload {
	remove_ids?: number[];
	add_ids?: number[];
	association_type: EBranchesAssociationPayloadAssociationType;
}

// * Deep Link
export interface IVendorDeepLink {
	id: number;
	link: string;
	title: string;
	description: string;
	image?: string;
}

export interface IVendorLinkEventStats {
	count: string;
	event: EVendorLinkEventStatsEvent;
	platform: EVendorLinkEventStatsPlatform;
}

export interface IVendorDeepLinkAnalytics {
	linkEventStats: IVendorLinkEventStats[];
}

// CRUD ---------
// * Deep Link
export interface ICreateVendorDeepLinkInfo extends Pick<IVendorDeepLink, 'title' | 'description'> {}

// * Bank details
interface IVendorBankInfo {
	account_number: string;
	holder_name: string;
	bank_name: string;
	bank_location: string;
	payment_email: string;
	bic_swift_code: string;
	updated_at: string;
}

export interface IUpdateVendorBankInfo extends Omit<IVendorBankInfo, 'updated_at'> {}

// * Snooze Menu Product
export interface IToggleSnoozeProductPayload extends IMenuTreeCoordinate {
	number_of_minutes: number;
}
