import { IMenu } from 'types/api';
import { EStatus, IGenericEntity } from 'types/common';

export enum EIsSubCategory {
	YES = 'yes',
	NO = 'no',
}

export interface IDefaultCategory {
	id: number;
	name: string;
	name_ar: string;
	sort_order: number;
}

// * Vendor Category
export interface IGenericVendorCategory extends IGenericEntity {
	icon: string;
}

export interface IVendorCategory {
	id: number;
	name: string;
	name_ar: string;
	icon: string;
	status: EStatus;
	is_sub_category: EIsSubCategory;
	sort_order: number;

	// associated menus
	menus: Pick<IMenu, 'id' | 'name'>[];
}

export interface ICategoryListItemResponse {
	id: IVendorCategory['id'];
	name: IVendorCategory['name'];
	name_ar: IVendorCategory['name_ar'];
	icon: IVendorCategory['icon'];
	status: IVendorCategory['status'];
	is_sub_category: IVendorCategory['is_sub_category'];

	// associated menus
	menus: Array<{
		id: IMenu['id'];
		name: IMenu['name'];
	}>;
}

export interface IVendorAddCategoryPayload {
	category_id: number;
	status: EStatus;
	name: string;
	name_ar: string;
}

export interface IVendorUpdateCategoryPayload extends Pick<IVendorCategory, 'id' | 'status'> {
	id: number;
	status: EStatus;
}
